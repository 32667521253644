import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Toolbar, Grid } from '@mui/material/';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import OfferIcon from '@mui/icons-material/LocalOffer';
import EthicsIcon from '@mui/icons-material/VerifiedUser';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { AppBar, Button, IconButton, Divider } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import Statutes from './Statutes';
import '../styles/toolbar.css';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
export default function App_Toolbar() {
  function scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const [selectedOfferMore, setSelectedOfferMore] = useState<boolean>(false);
  const handleClickMore = () => setSelectedOfferMore(true);
  const handleCloseMore = () => setSelectedOfferMore(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const list = (anchor: string) => (
    <Box
      sx={{ width: 250 }}
      role='presentation'
      onClick={() => setMenuOpen(false)}
      onKeyDown={() => setMenuOpen(true)}
    >
      <List>
        <ListItem
          onClick={() => scrollToSection('offer_section')}
          key='Oferta'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <ListItemText primary='Oferta' />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => scrollToSection('about_section')}
          key='O nas'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary='O nas' />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => scrollToSection('team_section')}
          key='Zespół'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary='Zespół' />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => scrollToSection('gallery_section')}
          key='Galeria'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <InsertPhotoIcon />
            </ListItemIcon>
            <ListItemText primary='Galeria' />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => scrollToSection('contact_section')}
          key='Kontakt'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <ContactPageIcon />
            </ListItemIcon>
            <ListItemText primary='Kontakt' />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => handleClickMore()}
          key='Regulamin'
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <EthicsIcon />
            </ListItemIcon>
            <ListItemText primary='Regulamin' />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => scrollToSection('offer_section')}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary='Umów wizytę' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className='appBar' position='static'>
        <Toolbar>
          <Grid container xs={12}>
            <Grid md={4}>
              <Button className='toolbar_Button'>
                <img
                  src={require('../uploads/amed_logo.png')}
                  className='toolbar_logo'
                  alt='AMED-logo'
                />
              </Button>
            </Grid>

            <Grid container md={8} className='toolbar_menu'>
              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('offer_section')}
              >
                Oferta
              </Button>
              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('about_section')}
              >
                O nas
              </Button>

              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('team_section')}
              >
                Zespół
              </Button>
              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('gallery_section')}
              >
                Galeria
              </Button>
              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('contact_section')}
              >
                Kontakt
              </Button>
              <Button
                className='toolbar_Button'
                onClick={() => scrollToSection('offer_section')}
              >
                Umów wizytę
              </Button>
              <Button
                className='toolbar_Button'
                onClick={() => handleClickMore()}
              >
                Regulamin
              </Button>
            </Grid>

            <IconButton
              sx={{
                position: 'absolute',
                right: 20,
                top: 10,
              }}
              edge='end'
              className='menuButton'
              color='inherit'
              aria-label='menu'
              onClick={() => setMenuOpen(true)}
            >
              <MenuIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor='right'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
      >
        {list('right')}
      </SwipeableDrawer>
      {selectedOfferMore && <Statutes onClose={handleCloseMore} />}
    </Box>
  );
}
