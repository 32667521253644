import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/Dashboard.tsx';
import React, { useEffect } from 'react';
import './styles/app.css';
import App_Toolbar from './pages/App_Toolbar.tsx';
function App() {
  return (
    <>
      <App_Toolbar />
      <Router>
        <Routes>
          <Route path='/home' element={<Dashboard />} />
          <Route path='*' element={<Dashboard />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
