import * as React from 'react';
import { Grid } from '@mui/material/';
import { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StarRateIcon from '@mui/icons-material/StarRate';
import ChairIcon from '@mui/icons-material/Chair';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import SellIcon from '@mui/icons-material/Sell';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import '../styles/app.css';
import '../styles/about.css';
export default function About() {
  const description = [
    'AMED Centrum Dobrych Zmian to kameralna bezpieczna przestrzeń która jest stworzona specjalnie dla Ciebie.',
    'Panuje tu miła i rodzinna atmosfera. Pomagamy ludziom na każdym etapie życia, wspieramy w pokonywaniu wszelkich problemów i ułatwiamy proces głębokiej potrzeby rozwoju osobistego.',
    'Pomagamy każdemu, nawet jeśli problem dotyczy stresu i codziennych napięć. Nie trzeba zwlekać z poradą do momentu, w którym rzeczywiście jest już źle.',
    'Zachęcamy do zapobiegania problemom, wyprzedzeniu najtrudniejszych chwil i niedoprowadzaniu do większych kryzysów czy dolegliwości zdrowotnych np. na tle nerwowym.',
    'Zależy nam także na tym, by wizyty były pozbawione stresu, który czasami pojawia się przed spotkaniem z terapeutą.',
  ];
  return (
    <Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{ textAlign: 'center' }}
        className='title'
        id='about_section'
      >
        O nas
      </Grid>
      <Grid
        container
        sx={{ marginTop: 5, alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid
          xs={12}
          md={6}
          container
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <img
            src={require('../uploads/amed_logo_1.png')}
            className='about_logo'
            alt='AMED-logo'
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          container
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {description.map((item, index) => (
            <p className='about_content' key={index}>
              {item}
            </p>
          ))}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          style={{ textAlign: 'center' }}
          className='title'
          id='about_section'
        >
          Co nas wyróżnia
        </Grid>
        <Statistics />

        <Grid
          item
          md={12}
          xs={12}
          style={{ textAlign: 'center' }}
          className='title'
          id='about_section'
        >
          Udogodnienia
        </Grid>
        <Advantages />
      </Grid>
    </Grid>
  );
}
function Statistics() {
  return (
    <Grid
      container
      gap={5}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        marginTop: '25px',
        padding: '5px',
      }}
    >
      <CountUpAnimation
        iconComponent={<ChairIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={150}
        img={require('../uploads/Rooms/Amed_8.jpg')}
        text='Domowa atmosfera'
        description='Gabinet charakteryzuje się domową, kameralną atmosferą, pełną ciepła, spokoju i harmonii, sprzyjającą poczuciu bezpieczeństwa i komfortu.'
      />
      <CountUpAnimation
        iconComponent={<FamilyRestroomIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={2000}
        img={require('../uploads/Rooms/Amed_17.JPEG')}
        text='Przyjazne dzieciom'
        description='Gabinet jest przyjazny dzieciom, oferując bezpieczne, radosne i inspirujące środowisko sprzyjające zabawie i nauce. Posiadamy szeroki asortyment gier i zabaw edukacyjno terapeutycznych'
      />
      <CountUpAnimation
        iconComponent={<WorkspacePremiumIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={15}
        img={require('../uploads/Rooms/Amed_7.jpg')}
        text='Wykwalifikowany zespół'
        description='Gabinet może pochwalić się wykwalifikowanym zespołem, który gwarantuje wysoką jakość usług i indywidualne podejście do klientów.'
      />
      <CountUpAnimation
        iconComponent={<GroupsIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={5}
        img={require('../uploads/Rooms/Amed_6.jpg')}
        text='Zaufanie i poufność'
        description='Zapewnienie klientom poczucia bezpieczeństwa w dzieleniu się swoimi myślami i uczuciami, gwarantując przy tym pełną poufność.'
      />
      <CountUpAnimation
        iconComponent={<GroupsIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={5}
        img={require('../uploads/Rooms/Amed_11.jpg')}
        text='Empatia i wsparcie'
        description='Zespół wykazuje zrozumienie i współczucie, tworząc atmosferę, w której klienci czują się akceptowani.'
      />
      <CountUpAnimation
        iconComponent={<GroupsIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={5}
        img={require('../uploads/Rooms/Amed_9.jpg')}
        text='Indywidualne podejście'
        description='Każdy klient traktowany jest wyjątkowo, z uwzględnieniem jego unikalnych potrzeb i problemów.'
      />
    </Grid>
  );
}
function Advantages() {
  return (
    <Grid
      container
      gap={5}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        marginTop: '25px',
        padding: '5px',
      }}
    >
      <CountUpAnimation
        iconComponent={<BookOnlineIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={150}
        img={require('../uploads/Rooms/Amed_12.jpg')}
        text='Rejestracja online'
        description='Całodobowa rejestracja online z możliwością wyboru terapeuty.'
      />
      <CountUpAnimation
        iconComponent={<SellIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={150}
        img={require('../uploads/Rooms/Amed_14.jpg')}
        text='Płatności'
        description='Dostępne płatności to BLIK oraz gotówka.'
      />
      <CountUpAnimation
        iconComponent={<TimeToLeaveIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={5}
        img={require('../uploads/Rooms/Amed_13.jpg')}
        text='Bezpłatny parking dla klientów'
        description='Dysponujemy parkingiem dla klientów, w wyznaczonych miejscach.'
      />
      <CountUpAnimation
        iconComponent={<LocationOnIcon sx={{ fontSize: 80 }} />}
        initialValue={0}
        targetValue={5}
        img={require('../uploads/Rooms/Amed_15.jpg')}
        text='Lokalizacja'
        description='Nasz obiekt jest zlokalizowany w centrum Gdowa.'
      />
    </Grid>
  );
}
const CountUpAnimation = ({
  iconComponent,
  initialValue,
  targetValue,
  img,
  description,
  text,
}: {
  iconComponent: React.ReactNode;
  initialValue: number;
  targetValue: number;
  img: string;
  description: string;
  text: string;
}) => {
  const [count, setCount] = useState(initialValue);
  const duration = 4000;

  useEffect(() => {
    let startValue = initialValue;
    const interval = Math.floor(duration / (targetValue - initialValue));

    const counter = setInterval(() => {
      startValue += 1;
      setCount(startValue);
      if (startValue >= targetValue) {
        clearInterval(counter);
      }
    }, interval);

    return () => {
      clearInterval(counter);
    };
  }, [targetValue, initialValue]);

  return (
    <div className='second hero'>
      <img className='hero-profile-img' src={img} alt='' />
      <div className='hero-description-bk'></div>
      <div className='hero-logo'>{iconComponent}</div>
      <div className='hero-description'>
        <p>{text}</p>
      </div>
      <div className='hero-date'>
        <p>{description} </p>
      </div>
    </div>
  );
};
