import * as React from 'react';
import { Grid, SvgIconTypeMap } from '@mui/material/';
import '../styles/offer.scss';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { OverridableComponent } from '@mui/material/OverridableComponent';
const ListComponent = ({
  list,
  key,
  icon: IconComponent,
}: {
  list: string[];
  key: number;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null;
}) => {
  const IconToRender = IconComponent || BeenhereIcon;
  return (
    <Grid key={key} style={{ marginBottom: '1rem' }}>
      <Grid container spacing={2}>
        {list.map((item, index) => (
          <Grid
            container
            item
            md={12}
            xs={12}
            key={index}
            alignItems='center'
            justifyContent='center'
          >
            <Grid
              item
              xs={2}
              style={{
                textAlign: 'center',
              }}
            >
              <IconToRender fontSize='medium' style={{ color: '#0a4870' }} />
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                textAlign: 'left',
              }}
            >
              <p className='contact_content' style={{ fontSize: '1rem' }}>
                {item}
              </p>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default ListComponent;
