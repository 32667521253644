import * as React from 'react';
import { Grid } from '@mui/material/';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import ListComponent from '../Components/ListComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../styles/offer.scss';

export default function Statutes(props: StatueProps) {
  const [open, setOpen] = React.useState(false);
  const MainStatue: Statue[] = [
    {
      id: '1.',
      class: 'disclaimer',
      content:
        'Czas trwania sesji Czas trwania pojedynczej sesji wynosi 50 minut',
      elements: [],
    },
    {
      id: '2.',
      class: 'disclaimer',
      content: 'Oferowane usługi:',
      elements: [
        'Konsultacja psychologiczna',
        'Konsultacja pedagogiczna',
        'Psychoterapia',
        'Interwencja kryzysowa',
        'Grupy wsparcia',
      ],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Umówiona wizyta odbywa się w gabinecie, który znajduje się na ulicy Łapanowskiej 119, 32-420 Gdów.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Specjalista nie ponosi odpowiedzialności za podanie przez Pacjenta niekompletnych, nieprawdziwych lub nieprawidłowych informacji, zwłaszcza w przypadku podania danych osób trzecich bez ich wiedzy lub zgody. Za skutki podania błędnych, niekompletnych, nieprawdziwych, wprowadzających w błąd lub w inny sposób nieprawidłowych danych wyłączna odpowiedzialność spoczywa na Pacjencie. Czas konsultacji jest przestrzenią do przedstawienia przez Państwa uciążliwych objawów oraz trudności. Wspólnie badamy także obszary, które mogą utrudniać satysfakcjonujące funkcjonowanie. Stanowi to podstawę, bazę do planu wspólnej pracy.',
      elements: [],
    },
    {
      id: '3.',
      class: 'disclaimer',
      content:
        'W gabinecie przyjmowane są osoby dorosłe oraz dzieci( od 7 lat) i młodzież ( za zgodą prawnego opiekuna) który wyraża zgodę na spotkanie i akceptuje niniejszy regulamin.',
      elements: [],
    },
    {
      id: '4.',
      class: 'disclaimer',
      content:
        'Psychologa / Psychoterapeutę obowiązuje zasada poufności, czyli pozostawia wyłącznie dla siebie wszystkie informacje.',
      elements: [],
    },
    {
      id: '5.',
      class: 'disclaimer',
      content:
        'Terminy spotkania ustalamy/ odwołujemy za pomocą strony amedcentrum.com poprzez całodobową rejestrację lub telefonicznie od poniedziałku do piątku w godzinach 8.00-15.00 pod numerem telefonu 535 770 997.',
      elements: [],
    },
    {
      id: '6.',
      class: 'disclaimer',
      content:
        'Sesje pomocy psychologicznej / psychoterapii poprzedza konsultacja najczęściej 1 do 3 spotkań , w wyniku której określa się cel pracy oraz częstotliwość spotkań.',
      elements: [],
    },
    {
      id: '7.',
      class: 'disclaimer',
      content:
        'Czas trwania konsultacji trwa 50 minut i podlega opłacie zgodnie z cennikiem. Standardowa sesja zaczyna się i kończy punktualnie. W razie spóźnienia, nie przedłuża się czasu spotkania, podkreślić należy również, iż sesje nieobecne PSYCHOTERAPII są tak samo płatne. W sytuacjach wyjątkowych można negocjować inne zasady płatności (np. odroczenie płatności).',
      elements: [],
    },
    {
      id: '8.',
      class: 'disclaimer',
      content:
        'Psychoterapeutka w trosce o świadczenie wysokiej jakości usług poddaje swoją pracę superwizji. W trakcie superwizji psychoterapeutka omawia kwestie związane z terapią pacjenta w taki sposób, aby uniemożliwić superwizorowi jego identyfikację. Superwizor zgodnie z przepisami prawa jest zobowiązany do przestrzegania tajemnicy zawodowej psychoterapeuty.',
      elements: [],
    },
    {
      id: '9.',
      class: 'disclaimer',
      content:
        'W przypadku, kiedy w ocenie terapeuty zdrowie lub życie pacjenta albo zdrowie lub życie osoby z jego otoczenia jest zagrożone, terapeuta ma prawo powiadomić o tym fakcie wskazane przez pacjenta osoby. W uzasadnionych przypadkach zagrożenia zdrowia lub życia terapeuta po przekazaniu takiej informacji klientowi, ma prawo powiadomić o tym stosowne służby.',
      elements: [],
    },
    {
      id: '10.',
      class: 'disclaimer',
      content:
        'Terapeuta, mając na względzie dobro pacjenta, ma prawo przerwać konsultacje , jeśli pacjent nie będzie mógł uczestniczyć w spotkaniu z powodu zaburzenia świadomości, np.: po spożyciu alkoholu lub innych środków zmieniających świadomość.Terapeuta ma prawo odmówić wykonania usługi lub przekierować do innego specjalisty z powodu: braku współpracy terapeuta-klient, braku motywacji ze strony klienta , braku kompetencji lub innych przyczyn osobistych .',
      elements: [],
    },
    {
      id: '11.',
      class: 'disclaimer',
      content:
        'Zasada odwoływania wizyt psychoterapii. Umówione spotkanie można anulować lub przesunąć na inny, możliwy termin ( 24 godziny przed planowanym spotkaniem.) Zmiany można dokonać telefonicznie bądź przez wiadomość SMS. Brak informacji dotyczącej zmiany terminu ze strony pacjenta, wiąże się z obowiązkiem zapłaty za planowaną sesję psychoterapii. ',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Pacjent zobowiązany jest wnieść opłatę za wszystkie sesje, z wyjątkiem:',
      elements: [
        'Sesji odwołanych przez psychoterapeutę. W tej sytuacji wspólnie, wraz z pacjentem, zostaje ustalony inny, dogodny termin.',
        'Sesji odwołanych z powodu hospitalizacji pacjenta.',
        'Sesji odwołanych, z co najmniej tygodniowym wyprzedzeniem spowodowanym wyjazdem urlopowym pacjenta. W ciągu roku nie więcej niż 2 przerwy urlopowe, nie dłuższe niż 2 tygodnie.',
      ],
    },
    {
      id: '12.',
      class: 'disclaimer',
      content:
        'Kontrakt psychoterapeutyczny. Podczas 4-5 konsultacji ustalamy tak zwany Kontrakt. Kontrakt ustny lub pisemny zawiera ogólne zasady pracy, częstotliwość spotkań, wstępną długość procesu oraz cel. Kontrakt terapeutyczny zawierany jest za zgodą obu stron .Kontrakt psychoterapeutyczny ustalany jest indywidualnie. Konsultacje przed rozpoczęciem psychoterapii są niezbędnym elementem. Klient ma prawo zaznajomić się z różnymi nurtami psychoterapii i wybrać odpowiedni dla siebie. Nie ponosimy odpowiedzialności za wybór nurtu w którym klient zdecydował się pracować. Zaleca się by psychoterapia odbywała się min. raz w tygodniu.',
      elements: [],
    },
    {
      id: '13.',
      class: 'disclaimer',
      content: 'Płatność Płatność gotówką/ blikiem po każdej sesji.',
      elements: [],
    },
    {
      id: '14.',
      class: 'disclaimer',
      content:
        'Punktualność Zaleca się przestrzegania konkretnych, wcześniej ustalonych godzin.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Jeżeli z jakichkolwiek przyczyn pacjent nie zdoła zjawić się punktualnie – ma on obowiązek poinformować o tym telefonicznie lub za pośrednictwem wiadomości SMS. W przypadku spóźnienia skraca się czas terapii. W przypadku spóźnienia się terapeuty, ma on obowiązek przedłużyć wizytę o czas spóźnienia.',
      elements: [],
    },
    {
      id: '15.',
      class: 'disclaimer',
      content:
        'Tajemnica zawodowa. Jako terapeuta zobowiązuję się do zachowania w tajemnicy informacji uzyskanych w trakcie sesji.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Wyjątkiem od zachowania tajemnicy są sytuacje, gdy:',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: '',
      elements: [
        'Tak stanowią inne ustawy',
        'Zagrożone jest życie lub zdrowie pacjenta lub innych osób.',
      ],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Tym samym oświadczam, iż nie prowadzę dokumentacji psychoterapeutycznej. Ewentualne notatki sporządzone w trakcie sesji oraz wszelkie dane są jedynie do wglądu dla mojego użytku.W ich treści nie widnieją żadne informacje, które umożliwiłyby zidentyfikowanie konkretnej osoby.',
      elements: [],
    },
    {
      id: '16.',
      class: 'disclaimer',
      content:
        'Korzystanie z pomocy przez osoby bliskie Ze względu na skuteczność psychoterapii oraz dobro pacjentów, nie zaleca się podejmowania terapii u tego samego psychoterapeuty przez osoby spokrewnione lub będące w bliskich relacjach w zbliżonym czasie.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Tym samym, zastrzegamy sobie prawo odmówienia rozpoczęcia pracy terapeutycznej w w.w sytuacji.',
      elements: [],
    },
    {
      id: '17.',
      class: 'disclaimer',
      content:
        'Administratorem danych osobowych Klienta jest AMED CENTRUM DOBRYCH ZMIAN. Dane Klientów nie podlegają udostępnianiu podmiotom trzecim. Dokumentacja tworzona podczas wizyt jest niezbędna, jako narzędzie dla terapeuty, w związku z tym pozostaje tylko do jego wglądu.',
      elements: [],
    },
    {
      id: '18.',
      class: 'disclaimer',
      content: 'Nasz obiekt jest monitorowany. Celem monitoringu jest:',
      elements: [
        'Zapewnienie oraz zwiększenie bezpieczeństwa użytkowników obiektu (ze szczególnym uwzględnieniem pracowników i klientów oraz osób przebywających naterenie obiektu)',
        'Zapewnienie oraz zwiększenie bezpieczeństwa mienia oraz danych administrowanych.',
        'Przestrzeganie bezpiecznych i higienicznych warunków użytkowania obiektu.',
        'Ograniczenie zachowań niepożądanych, destrukcyjnych, zagrażających zdrowiu, bezpieczeństwu użytkowników obiektu.',
      ],
    },
    {
      id: '19.',
      class: 'disclaimer',
      content:
        'Nie ponosimy odpowiedzialności za osoby dorosłe/dzieci/młodzież przebywające w poczekalni, toalecie lub na terenie budynku.',
      elements: [],
    },
  ];
  const ChildStatue: Statue[] = [
    {
      id: null,
      class: 'disclaimer',
      content:
        'Pierwsze spotkanie odbywa się zawsze z samym rodzicem/ rodzicami bez obecności dziecka. Na pierwszym spotkaniu ustalane są cele i omawiana jest sytuacja dziecka. oraz dalszy plan działania.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Przyjmujemy dzieci od 7 roku życia.',
      elements: [],
    },
    {
      id: '1.',
      class: 'disclaimer',
      content:
        'Dziecko tak samo jak osoba dorosła ma prawo do pełnej poufności. Psycholog nie ma obowiązku informowania rodzica/opiekuna o wszystkim, co mówi mu dziecko, chyba, że informacja, którą uzyskał jest istotna dla dalszego procesu terapeutycznego, bądź zagraża zdrowiu, bezpieczeństwu lub życiu dziecka.Terapeuta przekazuje Opiekunom ogólne wnioski i zalecenia z przebiegu spotkania z Małoletnim. Jeżeli Terapeuta rozmawia z Opiekunem na temat sytuacji Małoletniego, stara się, aby Małoletni nie był obecny przy rozmowie. Jeżeli obecność Małoletniego jest niezbędna, Terapeuta pamięta o tym w czasie rozmowy i kieruje swoje wypowiedzi do wszystkich obecnych.',
      elements: [],
    },
    {
      id: '2.',
      class: 'disclaimer',
      content:
        'Psycholog może zlecić dodatkowe konsultacje u innego specjalisty lub badania lekarskie w celu dogłębnej diagnozy i jak najlepszych efektów terapii.',
      elements: [],
    },
    {
      id: '3.',
      class: 'disclaimer',
      content:
        'Konsultacje i spotkania terapeutyczne przeprowadzane są na zlecenie rodzica/opiekuna. W przypadku młodzieży powyżej 16 rż, zgodę wyraża rodzic oraz niepełnoletni Klient. Zakres oraz dobór zajęć terapeutycznych dla dziecka rodzic/opiekun ustala z terapeutą. Rodzic/opiekun zobowiązany jest do przekazania terapeucie wszelkich informacji o stanie zdrowia dziecka. W przypadku dzieci, terapeuta ma obowiązek informować rodzica/opiekuna o przebiegu i postępach terapii (zachowując tajemnicę terapeutyczną wobec niepełnoletniego Klienta a rodzic/opiekun przekazuje informację zwrotną dotyczącą efektywności zajęć, zachodzących zmian w funkcjonowaniu dziecka itp.',
      elements: [],
    },
    {
      id: '4.',
      class: 'disclaimer',
      content:
        'Zazwyczaj 1-3 spotkań to zapoznanie dziecka z terapeutę oraz zdobywanie zaufania i poczucia bezpieczeństwa podczas spotkań. Często terapeuta dla rozluźnienia atmosfery wprowadza gry i zabawy w początkowym etapie budowania więzi.',
      elements: [],
    },
    {
      id: '5.',
      class: 'disclaimer',
      content:
        'Jeżeli dziecko zgłasza myśli samobójcze rodzic jest o tym informowany. Dziecko również otrzymuje informację o przekazaniu tej wiadomości rodzicowi . Podpisywany jest kontrakt na życie. Rodzic zostaje zobowiązany do pilnej konsultacji u psychiatry.',
      elements: [],
    },
    {
      id: '6.',
      class: 'disclaimer',
      content:
        'Każde nowe rozpoczęcie terapii dziecka wymaga podpisania zgody rodzica na udział w konsultacji.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'STANDARDY OCHRONY MAŁOLETNICH',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Naszymi pacjentami są m.in. Małoletni. W przypadku świadczenia usług na rzecz Małoletnich, naszym priorytetem jest zawsze ich dobro. Traktujemy wszystkich Małoletnich z należnym im szacunkiem, uwzględniając ich interesy i potrzeby.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Zasady bezpiecznego kontaktu z Małoletnim:',
      elements: [],
    },
    {
      id: '1.',
      class: 'disclaimer',
      content: 'Przed rozpoczęciem współpracy Terapeuta:',
      elements: [
        'Przedstawia Małoletniemu i jego Opiekunom zasady współpracy (pisemnie lub ustnie) i prosi o potwierdzenie, że wyrażają na nie zgodę.',
        'Prosi Opiekunów, a w przypadku, gdy Małoletni ukończył 16 lat - także jego, o wyrażenie zgody na świadczenie konkretnej usługi (konsultacja itp.).',
      ],
    },
    {
      id: '2.',
      class: 'disclaimer',
      content:
        'Jeżeli władza rodzicielska przysługuje obydwojgu rodzicom, wskazane, ale nie niezbędne, jest uzyskanie zgody obydwojga rodziców. Jeżeli nie ma możliwości uzyskania zgody obydwojga rodziców, wystarczająca jest zgoda jednego z nich. W przypadku braku porozumienia między rodzicami, Terapeuta informuje ich o konieczności rozstrzygnięcia sprawy spornej przez sąd.',
      elements: [],
    },
    {
      id: '3.',
      class: 'disclaimer',
      content: 'Na pierwszym spotkaniu z Małoletnim Terapeuta:',
      elements: [
        'Przedstawia się, mówi kim jest i na czym będzie polegać jego współpraca z Małoletnim (np. o czym będą rozmawiać itp.).',
        'Informuje Małoletniego, że w każdej chwili może zadawać pytania oraz informować o problemach, trudnych sytuacjach, np. w domu albo w szkole.',
        'Ustala z Opiekunami czy i w jakim zakresie będą oni uczestniczyć w spotkaniach z Małoletnim - jeżeli okaże się, że Małoletni potrzebuje obecności Opiekuna w większym zakresie, pierwotnie ustalone zasady mogą zostać zmodyfikowane.',
      ],
    },
    {
      id: '4.',
      class: 'disclaimer',
      content:
        'Przy każdym kontakcie z Małoletnim Terapeuta bierze pod uwagę jego wiek, stan zdrowia, możliwości poznawcze i indywidualne potrzeby.',
      elements: [],
    },
    {
      id: '5.',
      class: 'disclaimer',
      content:
        'Wszystkie informacje Terapeuta przekazuje Małoletniemu w sposób dostosowany do jego wieku i możliwości poznawczych.',
      elements: [],
    },
    {
      id: '6.',
      class: 'disclaimer',
      content:
        'Terapeuta zwraca się do Małoletniego po imieniu, w preferowanej przez niego formie.',
      elements: [],
    },
    {
      id: '7.',
      class: 'disclaimer',
      content:
        'Kontakt fizyczny z Małoletnim, który nie jest związany z udzielaniem świadczenia zdrowotnego  (np. przytulenie), może odbywać się wyłącznie za jego zgodą.',
      elements: [],
    },
    {
      id: '8.',
      class: 'disclaimer',
      content:
        'Terapeuta nie ignoruje i nie bagatelizuje odczuć Małoletniego, np. strachu, wstydu, niepewności co do nowej sytuacji.',
      elements: [],
    },
    {
      id: '9.',
      class: 'disclaimer',
      content:
        'Małoletni ma prawo do własnych opinii i poglądów, musi je jednak wyrażać w sposób kulturalny, z szacunkiem dla innych.',
      elements: [],
    },
    {
      id: '10.',
      class: 'disclaimer',
      content:
        'Terapeuta nie kontaktuje się z Małoletnim prywatnymi kanałami komunikacji bez wiedzy i zgody Opiekunów.',
      elements: [],
    },
    {
      id: '11.',
      class: 'disclaimer',
      content:
        'Małoletniemu przysługuje prawo do poufności. Terapeuta przekazuje Opiekunom ogólne wnioski i zalecenia z przebiegu spotkania z Małoletnim.',
      elements: [],
    },
    {
      id: '12.',
      class: 'disclaimer',
      content:
        'Jeżeli Terapeuta rozmawia z Opiekunem na temat sytuacji Małoletniego, stara się, aby Małoletni nie był obecny przy rozmowie. Jeżeli obecność Małoletniego jest niezbędna, Terapeuta pamięta o tym w czasie rozmowy i kieruje swoje wypowiedzi do wszystkich obecnych.',
      elements: [],
    },
    {
      id: '13.',
      class: 'disclaimer',
      content:
        'Terapeuta może zawsze, jeżeli zauważy taką potrzebę, zasugerować Opiekunom kontakt z innym specjalistą, np. psychiatrą.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Zasady podejmowania interwencji w przypadku podejrzenia krzywdzenia Małoletniego lub posiadania informacji o krzywdzeniu Małoletniego',
      elements: [],
    },
    {
      id: '1.',
      class: 'disclaimer',
      content:
        'Terapeuci posiadają odpowiednią wiedzę i w ramach wykonywanych obowiązków zwracają uwagę na czynniki ryzyka krzywdzenia Małoletnich.',
      elements: [],
    },
    {
      id: '2.',
      class: 'disclaimer',
      content:
        'Terapeuta reaguje zawsze, gdy podejrzewa krzywdzenie Małoletniego lub posiada informacje o krzywdzeniu Małoletniego.',
      elements: [],
    },

    {
      id: '3.',
      class: 'disclaimer',
      content:
        'Przez krzywdzenie Małoletniego należy rozumieć popełnienie czynu zabronionego lub czynu karalnego na jego szkodę przez jakąkolwiek osobę, przemoc wobec Małoletniego lub zagrożenie dobra Małoletniego, w tym jego zaniedbywanie.',
      elements: [],
    },
    {
      id: '4.',
      class: 'disclaimer',
      content:
        'W przypadku zidentyfikowania czynników ryzyka, Terapeuta może w pierwszej kolejności porozmawiać z Opiekunami, przekazując informacje na temat dostępnej oferty wsparcia i motywując ich do szukania pomocy.',
      elements: [],
    },
    {
      id: '5.',
      class: 'disclaimer',
      content:
        'Jeżeli Terapeuta podejrzewa, że zagrożone jest życie lub zdrowie Małoletniego, informuje o tym odpowiednie służby (np. dzwoni pod numer 112), a następnie sporządza notatkę i informuje Osobę Odpowiedzialną za Ochronę Małoletnich.',
      elements: [],
    },
    {
      id: '6.',
      class: 'disclaimer',
      content:
        'Jeżeli Terapeuta podejrzewa,  że Małoletni jest krzywdzony, lub jeżeli taka okoliczność została zgłoszona przez Małoletniego lub Opiekuna, a nie jest zagrożone życie lub zdrowie Małoletniego, Terapeuta sporządza notatkę i przekazuje ją Osobie Odpowiedzialnej za Ochronę Małoletnich.',
      elements: [],
    },
    {
      id: '7.',
      class: 'disclaimer',
      content:
        'Osoba Odpowiedzialna za Ochronę Małoletnich zapoznaje się ze sprawą i wspólnie z Terapeutą podejmuje decyzję co do dalszych działań.',
      elements: [],
    },
    {
      id: '8.',
      class: 'disclaimer',
      content:
        'W przypadku, gdy wobec Małoletniego popełniono przestępstwo, Osoba Odpowiedzialna za Ochronę Małoletnich lub Terapeuta sporządza zawiadomienie o możliwości popełnienia przestępstwa i przekazuje je do właściwej miejscowo policji lub prokuratury.',
      elements: [],
    },

    {
      id: '9.',
      class: 'disclaimer',
      content:
        'Przed skierowaniem sprawy do odpowiedniej instytucji Osoba Odpowiedzialna za Ochronę Małoletnich przeprowadza rozmowę z Małoletnim, Opiekunami, ewentualnie także z innymi osobami mającymi lub mogącymi mieć wiedzę o zdarzeniu i o sytuacji osobistej (rodzinnej, zdrowotnej) Małoletniego.',
      elements: [],
    },
    {
      id: '10.',
      class: 'disclaimer',
      content:
        'W przypadku, gdy z rozmowy z Opiekunami wynika, że nie są oni zainteresowani pomocą Małoletniemu, ignorują zdarzenie, Osoba Odpowiedzialna za Ochronę Małoletnich sporządza wniosek o wgląd w sytuację rodziny, który kieruje do właściwego sądu rodzinnego.',
      elements: [],
    },
    {
      id: '11.',
      class: 'disclaimer',
      content:
        'W przypadku, gdy z przeprowadzonych ustaleń wynika, że Opiekun zaniedbuje potrzeby psychofizyczne Małoletniego lub rodzina jest niewydolna wychowawczo, rodzina stosuje przemoc wobec dziecka, Osoba Odpowiedzialna za Ochronę Małoletnich informuje właściwy ośrodek pomocy społecznej o potrzebie pomocy rodzinie lub o konieczności wszczęcia procedury „Niebieskiej Karty”.',
      elements: [],
    },
    {
      id: '12.',
      class: 'disclaimer',
      content:
        'Osoba Odpowiedzialna za Ochronę Małoletnich sporządza notatkę z podjętych działań.',
      elements: [],
    },

    {
      id: '13.',
      class: 'disclaimer',
      content:
        'Osoba Odpowiedzialna za Ochronę Małoletnich informuje o podjętych działaniach Opiekunów.',
      elements: [],
    },
    {
      id: '14.',
      class: 'disclaimer',
      content: 'Dalsze działania prowadzą odpowiednie instytucje.',
      elements: [],
    },
    {
      id: '15.',
      class: 'disclaimer',
      content:
        'W przypadku, gdy Terapeuta zauważy sytuacje przemocowe pomiędzy samymi Małoletnimi (np. podczas zajęć grupowych), reaguje natychmiast, w sposób adekwatny do sytuacji, a następnie wspólnie z Osobą Odpowiedzialną za Ochronę Małoletnich podejmuje decyzję co do dalszych działań (np. ustala spotkanie z Opiekunami).',
      elements: [],
    },
    {
      id: '16.',
      class: 'disclaimer',
      content:
        'Nawet jeżeli sprawą krzywdzenia Małoletniego zajmą się inne służby (sąd, Policja, prokuratura, OPS), Terapeuta może kontynuować terapię, chyba że z uwagi na dobro Małoletniego nie będzie to wskazane. Terapeuta może też ustalić, w porozumieniu z innymi służbami lub specjalistami, plan pomocy Małoletniemu. Dodatkowo Terapeuta może poinformować Małoletniego lub Opiekunów o instytucjach, do których mogą się zgłosić w celu uzyskania pomocy.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Zasady zatrudniania personelu.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Przed nawiązaniem współpracy z członkiem Personelu, który będzie miał kontakt z Małoletnimi:',
      elements: [
        'Sprawdzamy kwalifikacje kandydata/kandydatki',
        'Pobieramy dane osobowe kandydata/kandydatki, w tym dane potrzebne do sprawdzenia jego/jej danych w Rejestrze Sprawców Przestępstw na Tle Seksualnym (imię i nazwisko, data urodzenia, PESEL, nazwisko rodowe, imię ojca, imię matki)',
        'Sprawdzamy kandydata/kandydatkę w Rejestrze Sprawców Przestępstw na Tle Seksualnym – rejestr z dostępem ograniczonym lub w Rejestrze osób, w stosunku do których Państwowa Komisja do spraw przeciwdziałania wykorzystaniu seksualnemu małoletnich poniżej lat 15 wydała postanowienie o wpisie w Rejestrze,',
      ],
    },
  ];
  const EthicStatue: Statue[] = [
    {
      id: null,
      class: 'disclaimer',
      content: 'Zasady ogólne',
      elements: [],
    },
    {
      id: 'Art. 1.',
      class: 'disclaimer',
      content:
        'Wykonując czynności zawodowe, psycholog zawsze dąży do tego, że kontakt z nim był pomocny dla drugiego człowieka czy grupy osób. Psycholog z racji swojego zawodu poczuwa się do udzielania pomocy psychologicznej w każdych okolicznościach, gdy zachodzi taka potrzeba.',
      elements: [],
    },
    {
      id: 'Art. 2.',
      class: 'disclaimer',
      content:
        'Psycholog jest świadomy szczególnej odpowiedzialności wynikającej ze specyfiki wykonywanego zawodu. Powinien znać granice swoich kompetencji i nie podejmuje się zadań przekraczających jego możliwości. Dokłada wszelkich starań, aby zapewnić jak najwyższy poziom wykonywanej pracy.',
      elements: [],
    },
    {
      id: 'Art. 3.',
      class: 'disclaimer',
      content:
        'Przyjmując określoną postawę wobec norm moralnych i obyczajowych w życiu prywatnym psycholog zdaje sobie sprawę, że jego decyzje w sprawach osobistych mogą wpływać na jakość jego czynności zawodowych a także rzutować na zaufanie społeczne do psychologii i psychologów. W społecznych oczekiwaniach zawarte są przy tym wysokie wymagania dotyczące przestrzegania zasad etycznych w życiu prywatnym przez osoby, których rola zawodowa obejmuje oddziaływania na drugiego człowieka.',
      elements: [],
    },
    {
      id: 'Art. 4.',
      class: 'disclaimer',
      content:
        'Obowiązkiem psychologa jest stały rozwój zawodowy i dążenie do stałego rozwoju osobistego. Kwalifikacje psychologa powinny odzwierciedlać aktualny poziom wiedzy i technik psychologicznych. Psycholog korzysta z dorobku nauki światowej. Przyjmowanie twierdzeń i metod wypracowanych w innych warunkach społecznych i kulturowych powinna poprzedzać krytyczna analiza możliwości ich wykorzystania w naszych warunkach.',
      elements: [],
    },
    {
      id: 'Art. 5.',
      class: 'disclaimer',
      content:
        'Psycholog ustosunkowuje się twórczo do zastanego dorobku swojej dyscypliny. Przyczynia się do rozwoju wiedzy, ulepszania metod badawczych, narzędzi diagnostycznych i technik terapeutycznych. Jest krytyczny wobec własnych dokonań, rozpowszechniając je ujawnia sposoby dotychczasowej weryfikacji. dąży do bezstronności i obiektywizmu w ocenie nowych technik oddziaływania psychologicznego i nieprofesjonalnych form pomocy psychologicznej oraz powstrzymuje się od działań utrudniających ich rozwój, nie mając po temu merytorycznych powodów.',
      elements: [],
    },
    {
      id: 'Art. 6.',
      class: 'disclaimer',
      content:
        'Psycholog udostępnia własny dorobek naukowy, przestrzegając zasad odpowiedzialności zawodowej, dobra osoby i dobra społecznego.',
      elements: [],
    },
    {
      id: 'Art. 7.',
      class: 'disclaimer',
      content:
        'Psycholog nie przedstawia niezgodnie z prawdą swojego wykształcenia, kompetencji profesjonalnych i doświadczenia zawodowego.',
      elements: [],
    },
    {
      id: 'Art. 8.',
      class: 'disclaimer',
      content:
        'Psycholog powstrzymuje się od wykonywania czynności zawodowych, jeżeli okoliczności zewnętrzne lub jego własny aktualny stan fizyczny czy psychiczny są tego rodzaju, że mogą istotnie obniżyć poziom wykonywanej pracy lub zakłócić bezstronność osądu zawodowego.',
      elements: [],
    },
    {
      id: 'Art. 9.',
      class: 'disclaimer',
      content:
        'Relacje między psychologami opierają się na wzajemnym szacunku i koleżeństwie, wynikającymi ze wspólnoty wartości i celów, świadomości rangi społecznej wykonywanego zawodu oraz przyjętej na siebie odpowiedzialności zawodowej.',
      elements: [],
    },
    {
      id: 'Art. 10.',
      class: 'disclaimer',
      content:
        'Psycholog nie jest obojętny na odstępstwa od zasad etyki zawodowej ze strony innych psychologów. Stwierdzając nieetyczne postępowanie kolegi w sprawach zawodowych lub dowiadując się o takim zachowaniu, psycholog stara się przekonać go o niewłaściwości jego czynów, korzystając gdy trzeba, z pomocy innych kolegów. Jeśli taka interwencja okazuje się nieskuteczna, psycholog zgłasza sprawę do zarządu oddziału PTP, który określa dalszy sposób jej załatwienia.',
      elements: [],
    },
    {
      id: 'Art. 11.',
      class: 'disclaimer',
      content:
        'Krytyczna ocena pracy i działalności innych psychologów nie powinna mieć charakteru deprecjonującego osobę i w żadnym przypadku nie może służyć do rozgrywek osobistych. Psycholog powstrzymuje się od wydawania takich ocen w obecności osób postronnych, dbając o nie podważanie zaufania do psychologii i psychologów.',
      elements: [],
    },
    {
      id: 'Art. 12.',
      class: 'disclaimer',
      content:
        'Psycholog wykazuje stałą troskę o prestiż i poziom wykonywania zawodu. Nie udostępnia specyficznych technik diagnozy psychologicznej osobom nieprzygotowanym do ich kompetentnego stosowania. Psycholog przeciwstawia się podejmowaniu działalności psychologicznej, a zwłaszcza stosowaniu specyficznych technik diagnostycznych i terapeutycznych przez osoby nieposiadające kwalifikacji psychologicznych.',
      elements: [],
    },
    {
      id: 'Art. 13.',
      class: 'disclaimer',
      content:
        'Psycholog nie może akceptować warunków pracy, które ograniczają jego niezależność zawodową, a zwłaszcza takich, które uniemożliwiają stosowanie zasad etyki zawodowej. Psycholog powinien domagać się poszanowania dla własnej niezależności, bez względu na pozycję jaką zajmuje w hierarchii zawodowej. Każdy psycholog ma obowiązek występować w obronie niezależności swych kolegów.',
      elements: [],
    },
    {
      id: 'Art. 14.',
      class: 'disclaimer',
      content:
        'Psycholog w swojej działalności zawodowej kieruje się przede wszystkim dobrem człowieka, uwzględniając społeczne znaczenie wykonywanych czynności. Nie stawia na pierwszym miejscu własnych korzyści, ani interesów innych osób, w szczególności osób zatrudniających psychologa.',
      elements: [],
    },
    {
      id: 'Art. 15.',
      class: 'disclaimer',
      content:
        'Psycholog w pracy zawodowej nie może podejmować się realizacji zadań naruszających prawa człowieka. Powinien domagać się przestrzegania prawa i jego stosowania przez osoby, z którymi współpracuje, w szczególności osoby zlecające mu zadania.',
      elements: [],
    },
    {
      id: 'Art. 16.',
      class: 'disclaimer',
      content:
        'Psycholog powinien szanować autonomię osób korzystających z jego pomocy, a w szczególności szanować ich prawo do podejmowania decyzji w sprawach ich życia osobistego. Pomoc psychologiczna nie może polegać na narzucaniu rozwiązań, nawet jeżeli psycholog uważa je za najlepsze.',
      elements: [],
    },
    {
      id: 'Art. 17.',
      class: 'disclaimer',
      content:
        'Psycholog unika wykorzystywania swojej pozycji zawodowej do uzyskiwania korzyści osobistych, majątkowych czy zawodowych.',
      elements: [],
    },
    {
      id: 'Art. 18.',
      class: 'disclaimer',
      content:
        'Psycholog nie podejmuje się czynności zawodowych, które mogłyby narazić go na konflikt interesów lub mogłyby prowadzić do podejrzeń o nieobiektywność. W przypadku zaistnienia konfliktu interesów, psycholog ma obowiązek wycofać się z takiej sytuacji.',
      elements: [],
    },
    {
      id: 'Art. 19.',
      class: 'disclaimer',
      content:
        'Psycholog powinien zapewnić osobom korzystającym z jego usług swobodę zgłaszania wątpliwości co do podejmowanych działań oraz ich konsekwencji. Wątpliwości te powinny być przedmiotem wspólnej analizy.',
      elements: [],
    },
    {
      id: 'Art. 20.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dostarczać osobom, z którymi współpracuje, informacji niezbędnych do zrozumienia sensu i skutków proponowanych działań oraz dawać możliwość wyboru pomiędzy różnymi formami pomocy.',
      elements: [],
    },
    {
      id: 'Art. 21.',
      class: 'disclaimer',
      content:
        'Psycholog zobowiązany jest do zachowania tajemnicy zawodowej. Tajemnica zawodowa obejmuje wszelkie informacje uzyskane w związku z wykonywaniem zawodu, w tym także fakt zgłoszenia się po pomoc psychologiczną.',
      elements: [],
    },
    {
      id: 'Art. 22.',
      class: 'disclaimer',
      content:
        'Psycholog zwolniony jest z obowiązku zachowania tajemnicy zawodowej wyłącznie w przypadkach przewidzianych przez prawo oraz w sytuacjach, w których bez ujawnienia tajemnicy niemożliwe jest uniknięcie bezpośredniego zagrożenia zdrowia lub życia osoby zainteresowanej lub innych osób.',
      elements: [],
    },
    {
      id: 'Art. 23.',
      class: 'disclaimer',
      content:
        'Współpraca z innymi specjalistami, w szczególności z lekarzami, odbywa się za wiedzą i zgodą osoby zainteresowanej. W przypadku osób niezdolnych do podejmowania decyzji w tym zakresie wymagana jest zgoda ich prawnych opiekunów.',
      elements: [],
    },
    {
      id: 'Art. 24.',
      class: 'disclaimer',
      content:
        'Wszelkie informacje uzyskane w ramach współpracy z innymi specjalistami objęte są tajemnicą zawodową, a ich wykorzystanie ogranicza się do celów związanych z konkretnym przypadkiem.',
      elements: [],
    },
    {
      id: 'Art. 25.',
      class: 'disclaimer',
      content:
        'Psycholog zobowiązany jest do przechowywania dokumentacji zawodowej w sposób zabezpieczający ją przed nieuprawnionym dostępem.',
      elements: [],
    },
    {
      id: 'Art. 26.',
      class: 'disclaimer',
      content:
        'Psycholog zapewnia osobom, które korzystają z jego usług, dostęp do dokumentacji ich dotyczącej, zgodnie z obowiązującymi przepisami prawa.',
      elements: [],
    },
    {
      id: 'Art. 27.',
      class: 'disclaimer',
      content:
        'Psycholog w pracy diagnostycznej powinien stosować tylko sprawdzone i odpowiednie do danego przypadku metody diagnostyczne.',
      elements: [],
    },
    {
      id: 'Art. 28.',
      class: 'disclaimer',
      content:
        'Psycholog unika sytuacji, w których może dojść do wykorzystywania wyników badań psychologicznych do celów innych niż te, dla których zostały przeprowadzone.',
      elements: [],
    },
    {
      id: 'Art. 29.',
      class: 'disclaimer',
      content:
        'Psycholog powinien informować osoby badane o celach, procedurach oraz potencjalnych skutkach przeprowadzanych badań.',
      elements: [],
    },
    {
      id: 'Art. 30.',
      class: 'disclaimer',
      content:
        'Wyniki badań psychologicznych powinny być przekazywane w sposób jasny, precyzyjny i zrozumiały, z uwzględnieniem możliwości odbiorcy.',
      elements: [],
    },
    {
      id: 'Art. 31.',
      class: 'disclaimer',
      content:
        'Psycholog nie powinien przekazywać wyników badań osobom postronnym bez zgody osoby badanej, chyba że przepisy prawa stanowią inaczej.',
      elements: [],
    },
    {
      id: 'Art. 32.',
      class: 'disclaimer',
      content:
        'W przypadku badań prowadzonych na zlecenie instytucji lub pracodawców, psycholog powinien uzyskać pisemną zgodę osoby badanej na przekazanie wyników badań.',
      elements: [],
    },
    {
      id: 'Art. 33.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dokładać wszelkich starań, aby wyniki badań były rzetelne, a interpretacje adekwatne do uzyskanych danych.',
      elements: [],
    },
    {
      id: 'Art. 34.',
      class: 'disclaimer',
      content:
        'Psycholog w pracy terapeutycznej powinien kierować się zasadą nieszkodzenia, dążąc do wspierania rozwoju osoby korzystającej z pomocy psychologicznej.',
      elements: [],
    },
    {
      id: 'Art. 35.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dostosować metody pracy terapeutycznej do specyfiki problemów osoby korzystającej z pomocy, a także jej potrzeb i możliwości.',
      elements: [],
    },
    {
      id: 'Art. 36.',
      class: 'disclaimer',
      content:
        'Psycholog powinien poinformować osobę korzystającą z pomocy o celach, procedurach oraz przewidywanych skutkach proponowanych działań terapeutycznych.',
      elements: [],
    },
    {
      id: 'Art. 37.',
      class: 'disclaimer',
      content:
        'Psycholog powinien zapewnić osobie korzystającej z pomocy możliwość rezygnacji z proponowanej formy terapii w każdym momencie.',
      elements: [],
    },
    {
      id: 'Art. 38.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dbać o to, aby relacja terapeutyczna nie naruszała godności ani autonomii osoby korzystającej z pomocy.',
      elements: [],
    },
    {
      id: 'Art. 39.',
      class: 'disclaimer',
      content:
        'Psycholog powinien unikać sytuacji, w których może dojść do wykorzystywania relacji terapeutycznej dla celów innych niż terapeutyczne.',
      elements: [],
    },
    {
      id: 'Art. 40.',
      class: 'disclaimer',
      content:
        'Psycholog powinien zakończyć terapię w momencie, gdy uzna, że dalsze działania terapeutyczne nie są konieczne lub mogą przynieść więcej szkody niż pożytku.',
      elements: [],
    },
    {
      id: 'Art. 41.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dążyć do zrozumienia kontekstu społecznego, kulturowego i ekonomicznego osoby korzystającej z jego pomocy.',
      elements: [],
    },
    {
      id: 'Art. 42.',
      class: 'disclaimer',
      content:
        'Psycholog powinien wspierać rozwój osoby korzystającej z pomocy, promując jej zdolność do podejmowania autonomicznych decyzji i działań.',
      elements: [],
    },
    {
      id: 'Art. 43.',
      class: 'disclaimer',
      content:
        'Psycholog powinien unikać angażowania się w relacje z osobą korzystającą z pomocy, które mogą zaburzyć obiektywizm i profesjonalizm jego działań.',
      elements: [],
    },
    {
      id: 'Art. 44.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dbać o to, aby proces terapii był dostosowany do indywidualnych potrzeb i możliwości osoby korzystającej z pomocy.',
      elements: [],
    },
    {
      id: 'Art. 45.',
      class: 'disclaimer',
      content:
        'Psycholog nie powinien podejmować działań terapeutycznych, które nie są oparte na naukowo uzasadnionych metodach.',
      elements: [],
    },
    {
      id: 'Art. 46.',
      class: 'disclaimer',
      content:
        'Psycholog powinien informować osoby korzystające z jego usług o alternatywnych formach pomocy psychologicznej.',
      elements: [],
    },
    {
      id: 'Art. 47.',
      class: 'disclaimer',
      content:
        'Psycholog nie może stosować presji ani manipulacji w celu skłonienia osoby do uczestnictwa w terapii.',
      elements: [],
    },
    {
      id: 'Art. 48.',
      class: 'disclaimer',
      content:
        'Psycholog powinien szanować prywatność i autonomię osób korzystających z jego usług, w szczególności unikać działań, które mogą naruszać te wartości.',
      elements: [],
    },
    {
      id: 'Art. 49.',
      class: 'disclaimer',
      content:
        'Psycholog powinien wspierać osoby korzystające z jego pomocy w procesie samodzielnego podejmowania decyzji i rozwiązywania problemów.',
      elements: [],
    },
    {
      id: 'Art. 50.',
      class: 'disclaimer',
      content:
        'Psycholog powinien dostosować swoje działania do specyficznych potrzeb i kontekstu osoby, z którą pracuje.',
      elements: [],
    },
    {
      id: 'Art. 51.',
      class: 'disclaimer',
      content:
        'Psycholog ma obowiązek przestrzegania zasad etyki zawodowej we wszystkich aspektach swojej pracy, dbając o dobro osób, z którymi współpracuje, oraz o prestiż zawodu psychologa.',
      elements: [],
    },
  ];
  const RodoStatue: Statue[] = [
    {
      id: '1.',
      class: 'disclaimer',
      content: 'Postanowienia Ogólne',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników odwiedzających stronę internetową amedcentrum.com.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Administratorem danych osobowych zbieranych za pośrednictwem Strony jest Amed Centrum Dobrych Zmian NIP 6811994598. Kontakt z Administratorem możliwy jest za pośrednictwem e-maila: amedcentrum119@gmail.com',
      elements: [],
    },
    {
      id: '2.',
      class: 'disclaimer',
      content: 'Zakres Danych Osobowych',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Administrator zbiera i przetwarza następujące dane osobowe:',
      elements: [
        'Dane kontaktowe - takie jak imię, nazwisko, adres e-mail, numer telefonu, które są niezbędne do realizacji zamówień, świadczenia usług i kontaktu z klientem.',
        'Dane techniczne – takie jak adres IP, dane nawigacyjne, pliki cookies i inne dane zbierane automatycznie podczas korzystania ze Strony.',
      ],
    },
    {
      id: '3.',
      class: 'disclaimer',
      content: 'Cele Przetwarzania Danych Osobowych',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Dane osobowe są przetwarzane w następujących celach:',
      elements: [
        'Realizacja zamówień na konsultacje psychologiczne, pedagogiczne, psychoterapię oraz grupy wsparcia.',
        'Wystawianie faktur i prowadzenie rachunkowości zgodnie z obowiązującymi przepisami prawa.',
        'Kontakt z użytkownikami, odpowiedzi na zapytania, obsługa klienta oraz wysyłanie powiadomień dotyczących realizacji zamówień.',
      ],
    },
    {
      id: '4.',
      class: 'disclaimer',
      content: 'Podstawa Przetwarzania Danych',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Administrator przetwarza dane osobowe na podstawie:',
      elements: [
        'Zgody użytkownika – na przykład w przypadku przetwarzania danych lub zapisania się na newsletter.',
        'Umowy – przetwarzanie danych jest konieczne do realizacji usług zamówionych przez klienta.',
        'Obowiązku prawnego – w przypadku przetwarzania danych w celach rachunkowych i podatkowych.',
        'Prawnie uzasadnionego interesu administratora – w celu prowadzenia marketingu bezpośredniego lub optymalizacji funkcjonowania Strony.',
      ],
    },
    {
      id: '5.',
      class: 'disclaimer',
      content: 'Odbiorcy Danych',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Administrator może udostępniać dane osobowe następującym podmiotom:',
      elements: [
        'Firmom świadczącym usługi płatnicze, niezbędne do realizacji transakcji w sklepie.',
        'Dostawcom usług IT, hostingu i wsparcia technicznego, którzy zapewniają prawidłowe funkcjonowanie Strony.',
        'Podmiotom księgowym i prawnym w zakresie niezbędnym do wypełnienia obowiązków prawnych.',
      ],
    },
    {
      id: '6.',
      class: 'disclaimer',
      content: 'Przekazywanie Danych do Państw Trzecich',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Dane osobowe mogą być przekazywane do państw trzecich (spoza Europejskiego Obszaru Gospodarczego) tylko wtedy, gdy jest to niezbędne do realizacji usług, a bezpieczeństwo danych zostanie odpowiednio zagwarantowane zgodnie z obowiązującymi przepisami.',
      elements: [],
    },
    {
      id: '7.',
      class: 'disclaimer',
      content: 'Okres Przechowywania Danych',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Dane osobowe będą przechowywane:',
      elements: [
        'Przez czas niezbędny do realizacji usług oraz wynikający z przepisów prawa (np. w celach rachunkowych i podatkowych).',
        'Do momentu cofnięcia zgody przez użytkownika w przypadku danych przetwarzanych na jej podstawie (np. zgoda na marketing).',
        'Do momentu złożenia skutecznego sprzeciwu w przypadku danych przetwarzanych na podstawie prawnie uzasadnionego interesu Administratora.',
      ],
    },
    {
      id: '8.',
      class: 'disclaimer',
      content: 'Prawa Użytkowsników',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content: 'Użytkownicy mają prawo do:',
      elements: [
        'Dostępu do swoich danych osobowych.',
        'Sprostowania nieprawidłowych lub niekompletnych danych.',
        'Usunięcia danych („prawo do bycia zapomnianym”), o ile dalsze przetwarzanie nie jest wymagane przepisami prawa.',
        'Ograniczenia przetwarzania danych.',
        'Przenoszenia danych do innego podmiotu.',
        'Wniesienia sprzeciwu wobec przetwarzania danych, szczególnie w celach marketingowych.',
        'Cofnięcia zgody na przetwarzanie danych w dowolnym momencie, jeśli przetwarzanie odbywa się na podstawie zgody.',
        'Złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uznają, że przetwarzanie danych narusza przepisy RODO.',
      ],
    },
    {
      id: '9.',
      class: 'disclaimer',
      content: 'Pliki Cookies',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Strona wykorzystuje pliki cookies, które służą do zbierania informacji na temat korzystania ze strony przez użytkowników.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Pliki cookies mogą być wykorzystywane w celach analitycznych, reklamowych oraz do zapewnienia prawidłowego działania Strony.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Użytkownik ma możliwość zablokowania plików cookies, zmieniając ustawienia przeglądarki internetowej, jednakże może to wpłynąć na ograniczenie funkcjonalności Strony.',
      elements: [],
    },
    {
      id: '10.',
      class: 'disclaimer',
      content: 'Zmiany Polityki Prywatności.',
      elements: [],
    },
    {
      id: null,
      class: 'disclaimer',
      content:
        'Administrator zastrzega sobie prawo do zmiany niniejszej Polityki Prywatności, w szczególności w przypadku zmiany przepisów prawa lub technologii wykorzystywanych na Stronie. Zmiany będą ogłaszane na Stronie internetowej, a użytkownicy zostaną o nich poinformowani.',
      elements: [],
    },
  ];
  const CancelationStatue: Statue[] = [
    {
      id: null,
      class: 'disclaimer',
      content:
        'W celu potwierdzenia wizyty nasz personel kontaktuje się z Państwem telefonicznie lub sms-owo dzień wcześniej. Jeżeli nie mamy odpowiedzi na sms-a, lub nie możemy się kilkakrotnie dodzwonić - automatycznie wizyta zostaje anulowana.  ',
      elements: [
        'Wizytę można odwołać w wyjątkowych sytuacjach najpóźniej 8 godzin przed planowaną wizytą.',
        'Jeżeli pacjent potwierdzi obecność na wizycie, a  nie pojawi  się na niej, jest zobligowany do wniesienia opłaty w kwocie 50 zł.',
      ],
    },
  ];
  const StatusesList: StatueInfo[] = [
    {
      title: 'Regulamin',
      items: MainStatue,
    },
    {
      title: 'Konsultacja psychologiczna dziecka',
      items: ChildStatue,
    },
    {
      title: 'Etyka pracy',
      items: EthicStatue,
    },
    {
      title: 'Polityka prywatności',
      items: RodoStatue,
    },
    {
      title: 'Odwoływanie i potwierdzanie wizyt',
      items: CancelationStatue,
    },
  ];
  const handleCloseMore = () => setOpen(false);
  return (
    <Backdrop
      sx={(theme) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(51px)',
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={open}
    >
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={true}
        onClose={handleCloseMore}
      >
        <Grid container className='dialog-container'>
          <DialogTitle>
            <IconButton onClick={props.onClose} className='close-btn'>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Grid>
        <DialogContent>
          <DialogContentText>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid className='slider-container'>
                <Divider
                  sx={{
                    marginTop: '10px',
                    fontWeight: 'bolder',
                  }}
                >
                  Regulamin
                </Divider>
                <Grid style={{ marginTop: '20px' }}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      {StatusesList.map((item, index) => (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <p
                              className='disclaimer'
                              style={{ fontWeight: 'bolder' }}
                            >
                              {item.title}
                            </p>
                          </AccordionSummary>
                          <AccordionDetails>
                            {item.items.map((subitem, subindex) =>
                              subitem.elements.length === 0 ? (
                                <p className={subitem.class} key={subindex}>
                                  <b>{subitem.id}</b> {subitem.content}
                                </p>
                              ) : (
                                <>
                                  <p className={subitem.class} key={subindex}>
                                    <b>{subitem.id}</b> {subitem.content}
                                  </p>
                                  <ListComponent
                                    icon={null}
                                    key={subindex}
                                    list={subitem.elements}
                                  />
                                </>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Divider
                  sx={{
                    marginTop: '10px',
                    fontWeight: 'bolder',
                  }}
                ></Divider>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
