import { Grid, Tooltip } from '@mui/material/';
import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';

interface ImageItem {
  img: string;
  title: string;
}
const Gallery: React.FC = () => {
  const [open, setOpen] = useState(false);
  const itemData: ImageItem[] = [];

  for (let i = 16; i <= 27; i++) {
    itemData.push({
      img: require(`../uploads/Rooms/Amed_${i}.JPEG`),
      title: `Amed ${i}/12`,
    });
  }
  const handleClickOpen = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(null);
  };
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const handleNext = () => {
    if (currentIndex !== null && currentIndex < itemData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <Grid container justifyContent='center' overflow={'hidden'}>
      <Grid
        item
        md={12}
        xs={12}
        style={{ textAlign: 'center' }}
        className='title'
        id='gallery_section'
      >
        Galeria
      </Grid>
      <ImageList
        sx={{
          width: '80%',
          height: '70%',
          gap: 16,
          position: 'relative',
        }}
        cols={3}
        rowHeight='auto'
      >
        {itemData.map((item, index) => (
          <ImageListItem
            key={item.img}
            onClick={() => handleClickOpen(index)}
            sx={{
              position: 'relative',
              cursor: 'pointer',
              overflow: 'hidden',
              '&:hover::before': {
                opacity: 0.9,
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                opacity: 0,
                transition: 'opacity 0.3s ease',
                zIndex: 1,
              },
              '& img': {
                transition: 'transform 0.3s ease',
                position: 'relative',
                zIndex: 0,
              },
            }}
          >
            <img
              srcSet={`${item.img}`}
              src={`${item.img}`}
              alt={item.title}
              title={item.title}
              loading='lazy'
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={open} onClose={handleClose} maxWidth='md'>
        <Tooltip title='Zamknij'>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[100],
            }}
          >
            <CloseIcon className='arrows' />
          </IconButton>
        </Tooltip>

        {currentIndex !== null && (
          <>
            {currentIndex > 0 && (
              <Tooltip title='Poprzednie zdjęcie'>
                <IconButton
                  aria-label='previous'
                  onClick={handlePrevious}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 8,
                    transform: 'translateY(-50%)',
                    color: (theme) => theme.palette.grey[100],
                    zIndex: 1,
                  }}
                  disabled={currentIndex === 0}
                >
                  <ArrowBackIosNewIcon className='arrows' />
                </IconButton>
              </Tooltip>
            )}
            {currentIndex < itemData.length - 1 && (
              <Tooltip title='Następne zdjęcie'>
                <IconButton
                  aria-label='next'
                  onClick={handleNext}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 8,
                    transform: 'translateY(-50%)',
                    color: (theme) => theme.palette.grey[100],
                    zIndex: 1,
                  }}
                  disabled={currentIndex === itemData.length - 1}
                >
                  <ArrowForwardIosIcon className='arrows' />
                </IconButton>
              </Tooltip>
            )}

            <img
              src={itemData[currentIndex].img}
              alt={itemData[currentIndex].title}
              style={{ width: '100%', height: 'auto' }}
            />
          </>
        )}
      </Dialog>
    </Grid>
  );
};

export default Gallery;
