import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Grid, Tooltip } from '@mui/material/';
import '../styles/dashboard.css';
import '../styles/button.scss';
import About from './About';
import Team from './Team';
import Offer from './Offer';
import Contact from './Contact';
import Gallery from './Gallery';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button } from 'semantic-ui-react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
export default function Dashboard() {
  function scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Grid>
      <Grid className='header'>
        <Grid className='inner-header'>
          <Grid className='main_card'>
            <Grid item md={6} xs={12} className='mainCardLeft' />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              lg={6}
              xs={12}
            >
              <img
                src={require('../uploads/amed_logo_1.png')}
                className='maincard_logo'
                alt='AMED-logo'
              />
              <button
                className='button'
                onClick={() => scrollToSection('offer_section')}
              >
                Umów wizytę
                <div className='button__horizontal'></div>
                <div className='button__vertical'></div>
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <svg
            className='waves'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 24 150 28'
            preserveAspectRatio='none'
            shape-rendering='auto'
          >
            <defs>
              <path
                id='gentle-wave'
                d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
              />
            </defs>
            <g className='parallax'>
              <use
                xlinkHref='#gentle-wave'
                x='48'
                y='0'
                fill='rgba(255,255,255,0.2'
              />
              <use
                xlinkHref='#gentle-wave'
                x='48'
                y='3'
                fill='rgba(255,255,255,0.5)'
              />
              <use xlinkHref='#gentle-wave' x='48' y='7' fill='#fff' />
            </g>
          </svg>
        </Grid>
      </Grid>
      <About />
      <Team />
      <Offer />
      <Gallery />
      <Contact />

      {isVisible && (
        <>
          <Tooltip placement='left' title='Przewiń na górę'>
            <ExpandLessIcon
              className={`scroll-to-top-btn ${isVisible ? 'show' : ''}`}
              onClick={scrollToTop}
            />
          </Tooltip>
          <a style={{ textDecoration: 'none' }} href='tel:48535770997'>
            <Tooltip placement='left' title='Zadzwoń do nas'>
              <LocalPhoneIcon
                className={`scroll-to-top-btn-phone ${isVisible ? 'show' : ''}`}
              />
            </Tooltip>
          </a>
        </>
      )}
    </Grid>
  );
}
