import * as React from 'react';
import { useState } from 'react';
import { Grid, Typography } from '@mui/material/';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import '../styles/booking.scss';

interface BookingProps {
  offer: Offer;
  onClose: () => void;
}
export default function Booking(props: BookingProps) {
  const [open, setOpen] = React.useState(false);
  const selectedOffer = props.offer;
  const handleClose = () => setOpen(false);
  const formatPrice = (price: number) => {
    return (
      price.toLocaleString('pl-PL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + ' zł'
    );
  };
  return (
    <Backdrop
      sx={(theme) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(51px)',
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={open}
    >
      <Dialog fullWidth={true} maxWidth='lg' open={true} onClose={handleClose}>
        <div className='dialog-container'>
          <DialogTitle>
            <a style={{ textDecoration: 'none' }} href='tel:48535770997'>
              <p className='dialog-title' style={{ color: '#0a4870' }}>
                Zadzwoń do nas i umów wizytę
              </p>
              <p className='dialog-title' style={{ color: '#0a4870' }}>
                +48 535 770 997
              </p>
            </a>
            <IconButton onClick={props.onClose} className='close-btn'>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='slider-container'>
                <Divider
                  sx={{
                    marginTop: '10px',
                    fontWeight: 'bolder',
                  }}
                >
                  PODSUMOWANIE
                </Divider>
                <Grid style={{ marginTop: '20px' }}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Grid
                        style={{
                          width: '100%',
                          height: '200px',
                          borderRadius: '10px 10px 0 0',
                          backgroundImage: `url(${selectedOffer.imageUrl})`,
                          backgroundSize: 'cover', // Cover the entire grid
                          backgroundPosition: 'center', // Center the image
                          backgroundRepeat: 'no-repeat', // Prevent repeating
                        }}
                      ></Grid>
                      <Grid className='title_info'>{selectedOffer.name}</Grid>
                      <Grid className='booking_content'>
                        {selectedOffer.description}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Divider
                  sx={{
                    marginTop: '10px',
                    fontWeight: 'bolder',
                  }}
                ></Divider>
                <Typography
                  variant='h5'
                  sx={{ fontWeight: 'bolder', textAlign: 'right' }}
                >
                  Łącznie:{selectedOffer.id === 2 ? 'Od ' : ''}
                  {formatPrice(selectedOffer.price)}
                </Typography>
              </div>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
